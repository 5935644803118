import React, { useEffect } from "react";
import "./Home.scss";
import { useState } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import axios from "axios";

const Home = () => {
  const [newsList, setNewsList] = useState<any>([]);

  const newsURL =
    "https://news-api14.p.rapidapi.com/top-headlines?country=us&language=en&pageSize=10&category=sports";
  const newsOptions = {
    method: "GET",
    headers: {
      "X-RapidAPI-Key": "d7a6bf784dmsh3716fc5f4cdf846p1b9631jsnbaa6d3167ad9",
      "X-RapidAPI-Host": "news-api14.p.rapidapi.com",
    },
  };

  useEffect(() => {
    const getNewsFunction = async () => {
      try {
        const response = await fetch(newsURL, newsOptions);
        const result = await response.json();
        setNewsList(result.articles);
        // console.log(response);
        // console.log(result);
        // console.log(result.articles);
      } catch (error) {
        console.error(error);
      }
    };
    getNewsFunction();
  }, []);

  return (
    <main className="home-page">
      <Row xs={1} md={3} className="g-4">
        {newsList.map(
          ({
            title,
            published_date,
            publisher,
            url,
          }: {
            title: string;
            published_date: string;
            publisher: any;
            url: string;
          }) => {
            return (
              <Col className="home-page__columns">
                <Card>
                  {/* <Card.Img variant="top" src={url} /> */}
                  <Card.Body>
                    <Card.Title>{title}</Card.Title>
                    <Card.Text>Published Date: {published_date}</Card.Text>
                    <Card.Text>Publisher: {publisher.name}</Card.Text>
                    <a href={url}>
                      <Card.Text>{url}</Card.Text>
                    </a>
                  </Card.Body>
                </Card>
              </Col>
            );
          }
        )}
      </Row>
    </main>
  );
};

export default Home;
